import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useQuery, useApolloClient } from "@apollo/client";

import { useTheme, useMediaQuery } from "@mui/material";
import PartnerTable from "./PartnerTable";
import SubRowAsync from "./SubRowAsync";
import { downloadLocalCsv, uppercaseFirstLetter } from "../../utils";

import columns from "./constants";
import PartnerFilters from "./PartnerFilters";

import { GET_EMPLOYEES } from "../../graphql/employeeDirectory";
import { SIGNED_FILE_URL_QUERY } from "../../graphql";
import { usePermissions } from "../../hooks";
import { FiltersContainer } from "../../components";
import MobilePartnerServices from "./MobilePartnerServices";

const PartnerServices = () => {
  const [directory, setDirectory] = useState([]);
  const [where, setWhere] = useState({
    employmentStatus: {
      equals: "active",
    },
  });
  const client = useApolloClient();
  const history = useHistory();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: { users: employeesData = [] } = {},
    loading: employeesLoading,
  } = useQuery(GET_EMPLOYEES, {
    variables: {
      orderBy: [{ firstName: "asc" }, { lastName: "asc" }],
      where: {
        AND: [where],
      },
    },
  });

  const permissionLevels = usePermissions();
  const isAdmin =
    permissionLevels.admin ||
    permissionLevels.superAdmin ||
    permissionLevels.webEditor ||
    permissionLevels.fieldSupervisor;

  const handleDownloadProfilePicture = async (data) => {
    const { profileImage } = data;
    if (!profileImage) return "";
    const path = profileImage.split("/")[4];
    const { data: signedFileUrlData } = await client.query({
      query: SIGNED_FILE_URL_QUERY,
      variables: {
        input: {
          objectKey: path,
        },
        fetchPolicy: "network-only",
      },
    });
    const { signedFileUrl } = signedFileUrlData;
    return signedFileUrl;
  };

  useEffect(() => {
    if (employeesData && !employeesLoading) {
      const grabRows = async () => {
        const rows = await Promise.all(
          employeesData
            .filter((eRow) => eRow?.firstName && eRow?.lastName)
            .map(async (row) => {
              const {
                firstName = "",
                lastName = "",
                jobTitle = "",
                email = "",
                middleInitial = "",
              } = row;

              const formattedName = `${uppercaseFirstLetter(firstName)} ${middleInitial?.toUpperCase() || ""
                }${middleInitial ? "." : ""} ${uppercaseFirstLetter(lastName)}`;

              const formattedTitle = jobTitle
                ? uppercaseFirstLetter(row.jobTitle)
                : "--";
              const formattedEmail = email?.toLowerCase();

              const profileImage = await handleDownloadProfilePicture(row);

              return {
                profileImg: profileImage || "",
                name: formattedName,
                firstName: uppercaseFirstLetter(firstName),
                lastName: uppercaseFirstLetter(lastName),
                title: formattedTitle,
                email: formattedEmail,
                employeeNumber: row.employeeNumber,
              };
            })
            .sort(
              (a, b) =>
                parseInt(a.employeeNumber?.trim(), 10) -
                parseInt(b.employeeNumber?.trim(), 10)
            )
        );
        setDirectory(rows);
      };
      grabRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesData, employeesLoading]);

  const handleCellClick = (cell) => {
    if (isAdmin && cell.column.id !== "expander") {
      localStorage.setItem("employeeNumber", cell.row.original.employeeNumber);
      history.push(`/baseball-card/${cell.row.original.employeeNumber.trim()}`);
    }
  };

  const exportCSV = () =>
    downloadLocalCsv(directory, "partnerServicesExport", [
      {
        Header: "Employee #",
        accessor: "employeeNumber",
      },
      {
        id: "firstName",
        accessor: "firstName",
        Header: "First Name",
      },
      {
        id: "lastName",
        accessor: "lastName",
        Header: "Last Name",
      },
      {
        Header: "Job Title",
        accessor: "title",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ]);

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  if (downSm) {
    return (
      <MobilePartnerServices
        totalResults={directory.length}
        onExportClick={exportCSV}
      />
    );
  }

  return (
    <div className="partner-services-container">
      <FiltersContainer onExportClick={exportCSV}>
        <PartnerFilters
          loading={employeesLoading}
          setWhere={setWhere}
          where={where}
          onExportClick={exportCSV}
        />
      </FiltersContainer>
      <PartnerTable
        columns={columns}
        data={directory}
        loading={employeesLoading}
        renderRowSubComponent={renderRowSubComponent}
        onCellClick={handleCellClick}
      />
    </div>
  );
};
PartnerServices.propTypes = {};

export default PartnerServices;
