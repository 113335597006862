import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select, Title } from "rbx";
import {
  useTheme,
  useMediaQuery,
} from "@mui/material";
import IncompleteEvaluationsList from "../IncompleteEvaluationsList";
import CompleteEvaluationsList from "../CompleteEvaluationsList";
import { useCurrentUser } from "../../../../hooks";
import { Searchbar } from "../../../../components";

const EvaluationsListContainer = ({
  reviews,
  isCompletedSection,
  sectionType,
  handleClick,
  setReviews,
  refetchMyReviews
}) => {
  const [isFilterResults, setIsFilterResults] = useState(true);
  const [filters, setFilters] = useState({
    inputSearch: "",
  });
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useCurrentUser();

  const activeEmployeeNumber = user?.employeeNumber;

  const allEvaluations = reviews.map((review) => review.evaluations).flat();


  // Year Select Values
  const goLiveYear = 2023;
  const currentYearPlusOne = new Date().getFullYear() + 1;
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => String(start + (i * step)));
  const uniqueYearOptions = range(goLiveYear, currentYearPlusOne, 1);

  const incompletePersonalEvals = allEvaluations.filter(
    (evaluation) =>
      evaluation.ownerEmployeeNumber === activeEmployeeNumber &&
      evaluation.authorEmployeeNumber === activeEmployeeNumber &&
      (evaluation.status === "INCOMPLETE" ||
        evaluation.status === "SAVE_FOR_LATER" ||
        (evaluation.status === "COMPLETED" && evaluation.needsSignature))
  );

  const incompletePeerEvals = allEvaluations?.filter(
    (evaluation) =>
      evaluation.ownerEmployeeNumber !== activeEmployeeNumber &&
      evaluation.authorEmployeeNumber === activeEmployeeNumber &&
      (evaluation?.title?.includes("Internal Client") ||
        evaluation?.title?.includes("Periodic Performance")) &&
      evaluation?.needsAssignment === false &&
      (evaluation.status === "INCOMPLETE" ||
        evaluation.status === "SAVE_FOR_LATER")
  );

  const incompleteManagerEvals = allEvaluations?.filter(
    (evaluation) =>
      // Annual Performance to complete
      (evaluation?.title?.includes("Annual Performance") &&
        (evaluation?.status === "INCOMPLETE" ||
          evaluation.status === "SAVE_FOR_LATER") &&
        evaluation.managerEmployeeNumber === activeEmployeeNumber) ||
      // Internal Client or PPA to assign
      ((evaluation?.title?.includes("Internal Client") ||
        evaluation?.title?.includes("Periodic Performance")) &&
        (evaluation?.status === "INCOMPLETE" ||
          evaluation.status === "SAVE_FOR_LATER") &&
        evaluation.managerEmployeeNumber === activeEmployeeNumber &&
        evaluation?.needsAssignment) ||
      // ANY EVAL THAT NEEDS APPROVAL to be signed
      (evaluation?.needsSignature &&
        evaluation?.status === "COMPLETED" &&
        evaluation?.authorEmployeeNumber === activeEmployeeNumber &&
        evaluation.ownerEmployeeNumber !== activeEmployeeNumber &&
        !evaluation?.signedUserId)
  );

  const completePersonalEvals = allEvaluations.filter(
    (evaluation) =>
      evaluation.status === "COMPLETED" &&
      evaluation.ownerEmployeeNumber === activeEmployeeNumber
  );

  const completePeerEvals = allEvaluations.filter(
    (evaluation) =>
      evaluation.status === "COMPLETED" &&
      (evaluation?.title?.includes("Internal Client") ||
        evaluation?.title?.includes("Periodic Performance")) &&
      evaluation.formAuthorEmployeeNumber === activeEmployeeNumber &&
      evaluation.ownerEmployeeNumber !== activeEmployeeNumber &&
      evaluation.managerEmployeeNumber !== activeEmployeeNumber
  );

  const completeManagerEvals = allEvaluations.filter(
    (evaluation) =>
      evaluation.status === "COMPLETED" &&
      evaluation.managerEmployeeNumber === activeEmployeeNumber
  );

  const [filteredResults, setFilteredResults] = useState(
    isCompletedSection ? completeManagerEvals : incompleteManagerEvals
  );

  const filterManagerEvals = (evals, filterVal) => {
    const filtered = evals.filter(
      (evaluation) =>
        evaluation?.title?.toLowerCase()?.includes(filterVal?.toLowerCase()) ||
        evaluation?.owner?.toLowerCase()?.includes(filterVal?.toLowerCase())
    );

    return filtered;
  };

  useEffect(() => {
    if (filters?.inputSearch?.length) {
      const evals = isCompletedSection
        ? completeManagerEvals
        : incompleteManagerEvals;

      const filteredManagerEvals = filters?.inputSearch?.length
        ? filterManagerEvals(evals, filters?.inputSearch)
        : evals;

      if (filteredManagerEvals?.length) {
        setIsFilterResults(true);
      } else {
        setIsFilterResults(false);
      }
      setFilteredResults(filteredManagerEvals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = (name, val) => {
    setFilters({ ...filters, [name]: val });
  };

  if (
    sectionType === "Peer" &&
    ((isCompletedSection && completePeerEvals?.length === 0) ||
      (!isCompletedSection && incompletePeerEvals?.length === 0))
  ) {
    return (
      <div className="centered-title">
        <Title size={6}>No peer evaluations found</Title>
      </div>
    );
  }

  if (
    sectionType === "Personal" &&
    ((isCompletedSection && completePersonalEvals?.length === 0) ||
      (!isCompletedSection && incompletePersonalEvals?.length === 0))
  ) {
    return (
      <div className="centered-title">
        <Title size={6}>No personal evaluations found</Title>
      </div>
    );
  }

  if (
    sectionType === "Manager" &&
    ((isCompletedSection && completeManagerEvals?.length === 0) ||
      (!isCompletedSection && incompleteManagerEvals?.length === 0))
  ) {
    return (
      <div className="centered-title">
        <Title size={6}>No manager evaluations found</Title>
      </div>
    );
  }

  if (sectionType === "Manager" && isCompletedSection) {
    return (
      <ul>
        <div className="searchBar">
          <div className="fullWidth">
            <Searchbar
              disabled={!completeManagerEvals?.length > 0}
              placeholder={downSm ? "Search" : "Search by an evaluation title or subject name..."}
              onChange={(value) => handleFilterChange("inputSearch", value)}
            />
          </div>
        </div>
        <div className="list-container">
          {!isFilterResults ? (
            <div style={{ margin: "1rem" }}>No results found...</div>
          ) : (
            <CompleteEvaluationsList
              evaluations={filteredResults}
              handleClick={handleClick}
              sectionType={sectionType}
            />
          )}
        </div>
      </ul>
    );
  }

  if (sectionType === "Manager" && !isCompletedSection) {
    return (
      <ul>
        <div className="searchBar">
          <div className="fullWidth">
            <Searchbar
              disabled={!incompleteManagerEvals?.length > 0}
              placeholder={downSm ? "Search" : "Search by an evaluation title or subject name..."}
              onChange={(value) => handleFilterChange("inputSearch", value)}
            />
          </div>
        </div>
        <div className="list-container">
          {!isFilterResults ? (
            <div style={{ margin: "1rem" }}>No results found...</div>
          ) : (
            <IncompleteEvaluationsList
              evaluations={filteredResults}
              handleClick={handleClick}
              refetchMyReviews={refetchMyReviews}
              sectionType={sectionType}
            />
          )}
        </div>
      </ul>
    );
  }

  if (isCompletedSection) {
    return (
      <ul>
        <div className="list-container">
          <CompleteEvaluationsList
            evaluations={allEvaluations}
            handleClick={handleClick}
            sectionType={sectionType}
          />
        </div>
      </ul>
    );
  }

  return (
    <ul>
      <div className="list-container">
        {reviews?.map((r, i) => (
          <div key={r?.id}>
            <IncompleteEvaluationsList
              evaluations={r.evaluations}
              handleClick={handleClick}
              refetchMyReviews={refetchMyReviews}
              sectionType={sectionType}
            />
          </div>
        ))}
      </div>
    </ul>
  );
};

EvaluationsListContainer.propTypes = {
  reviews: PropTypes.array.isRequired,
  sectionType: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool,
  refetchMyReviews: PropTypes.func,
  setReviews: PropTypes.func,
};

EvaluationsListContainer.defaultProps = {
  sectionType: "",
  isCompletedSection: false,
  refetchMyReviews: () => { },
  setReviews: () => { },
};

export default EvaluationsListContainer;
