import React, { useEffect, useState } from "react";
import { Title } from "rbx";
import PropTypes from "prop-types";
import Pagination from "../Pagination";

const DEFAULT_PAGINATION = {
  data: [],
  recordCount: 0,
  pageCount: 0,
};

const DashboardCard = ({ title, data, paginationSize }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pagination, setPagination] = useState({
    ...DEFAULT_PAGINATION,
  });

  useEffect(() => {
    if (pagination.data.length === 0 && data.length > 0) {
      setPagination({
        data: [...data].splice(0, paginationSize),
        recordCount: data.length,
        pageCount:
          data.length < paginationSize
            ? 1
            : Math.ceil(data.length / paginationSize),
      });
    }
  }, [data, pagination.data.length, paginationSize]);

  const linksPaginationChangeHandler = (nextPage) => {
    const paginated = [...data].splice(
      nextPage * paginationSize,
      paginationSize
    );
    setPagination((prev) => ({ ...prev, data: paginated }));
  };

  const clickNextHandler = () => {
    linksPaginationChangeHandler(currentPage + 1);
    setCurrentPage((prev) => prev + 1);
  };

  const clickPrevHandler = () => {
    linksPaginationChangeHandler(currentPage - 1);
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="dashboard-card-container">
      <div className="dashboard-card-wrapper">
        <Title>{title}</Title>
        <div className="dashboard-list-wrapper">
          {pagination?.data?.map((item) => (
            <div key={item.id}>
              {item.link ? (
                <a href={item.link} rel="noreferrer" target="_blank">
                  <span className="list-item-wrapper link-wrapper">
                    <span>{item.name}</span>
                    <span>{item.description}</span>
                  </span>
                </a>
              ) : (
                <span className="list-item-wrapper ">
                  <span>{item.name}</span>
                  <span>{item.description}</span>
                </span>
              )}
            </div>
          ))}
        </div>
        {pagination.recordCount > paginationSize && (
          <Pagination
            hidePageSizeSelector
            canNextPage={currentPage + 1 < pagination.pageCount}
            canPreviousPage={currentPage + 1 > 1}
            page={currentPage + 1}
            pageSize={paginationSize}
            pages={pagination.pageCount}
            recordCount={pagination.recordCount}
            setPageSize={() => null}
            onClickNext={clickNextHandler}
            onClickPrev={clickPrevHandler}
          />
        )}
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,

  paginationSize: PropTypes.number,
};

DashboardCard.defaultProps = {
  paginationSize: 10,
};

export default DashboardCard;
