import React, { useState, useEffect } from "react";

import { Control, Field, Title, Button, Icon, Tab } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useModal } from "../../context";
import { QaulificationsTable } from "./components";
import { FIND_MANY_CODES_QUERY } from "../../graphql";
import QualificationCodesModal from "./components/QualificationCodesModal/QualificationCodesModal";
import { downloadLocalCsvQualCodes } from "../../utils";

import {
  CERTS_COLUMNS,
  LICENSE_COLUMNS,
  SKILLS_COURSES_COLUMNS,
} from "./columns";
import MobileTable from "../ReportsTable/MobileTable";
import { AccordionItem, AccordionDetailsItem } from "..";
import QualificationCodeTableSelectMUI from "./components/QualificationCodeTableSelectMUI/QualificationCodeTableSelectMUI";
import BoxLoader from "../DetailedDataTable/BoxLoader/BoxLoader";

const QualificationCodes = ({ isAdmin, isReadOnly }) => {
  const { setModalOpen } = useModal();
  const [tableColumns, setTableColumns] = useState(CERTS_COLUMNS);
  const [tabState, setTabState] = useState("certifications");

  const {
    data: { findManyCodes: codes = [] } = {},
    loading: codesLoading,
    refetch,
  } = useQuery(FIND_MANY_CODES_QUERY, {
    variables: {
      orderBy: [
        {
          title: "asc",
        },
        {
          level: "asc",
        },
      ],
      where: {
        entity: {
          equals: tabState,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (tabState === "skills") {
      setTableColumns(SKILLS_COURSES_COLUMNS);
    } else if (tabState === "certifications") {
      setTableColumns(CERTS_COLUMNS);
    } else if (tabState === "licenses") {
      setTableColumns(LICENSE_COLUMNS);
    } else if (tabState === "deccoUCourses") {
      setTableColumns(SKILLS_COURSES_COLUMNS);
    }
  }, [tabState]);

  const getTableItemByTabState = (item) => {
    if (tabState === "certifications") {
      return (
        <AccordionItem info={item?.points} key={item?.id} title={item?.title}>
          <AccordionDetailsItem
            label="Expiration Type"
            value={item?.expirationType}
          />
          <AccordionDetailsItem label="Lookup Code" value={item?.lookupCode} />
          <AccordionDetailsItem label="Description" value={item?.description} />
        </AccordionItem>
      );
    }
    if (tabState === "skills") {
      return (
        <AccordionItem info={item?.points} key={item?.id} title={item?.title}>
          <AccordionDetailsItem label="Description" value={item?.description} />
        </AccordionItem>
      );
    }
    if (tabState === "licenses") {
      return (
        <AccordionItem key={item?.id} title={item?.title}>
          <AccordionDetailsItem label="Level" value={item?.level || "N/A"} />
          <AccordionDetailsItem label="State" value={item?.state || "N/A"} />
          <AccordionDetailsItem
            label="Expiration Type"
            value={item?.expirationType}
          />
          <AccordionDetailsItem label="Lookup Code" value={item?.lookupCode} />
          <AccordionDetailsItem label="Description" value={item?.description} />
        </AccordionItem>
      );
    }
    return null;
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <QualificationCodesModal
        qualification={tabState}
        onComplete={() => {
          refetch();
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleTabChange = (e) => {
    setTabState(e);
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileTable
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <QualificationCodeTableSelectMUI
                value={tabState}
                onChange={handleTabChange}
              />
            </Box>
          </React.Fragment>
        }
        loading={codesLoading}
        showExportButton={false}
        title="Qualification Codes"
      >
        <React.Fragment>
          {codesLoading ? (
            <BoxLoader />
          ) : (
            codes?.map((item) => getTableItemByTabState(item))
          )}
        </React.Fragment>
      </MobileTable>
    );
  }

  return (
    <div className="report-container">
      <div className="table-utils">
        <Button
          className="download-btn"
          disabled={codesLoading}
          size="medium"
          title="Download CSV"
          onClick={() => {
            downloadLocalCsvQualCodes(codes, tabState, tableColumns);
          }}
        >
          <Icon size="small">
            <FontAwesomeIcon icon="download" />
          </Icon>
        </Button>
        {!isReadOnly && (
          <Field>
            <Control>
              <Button className="add-btn" color="primary" onClick={handleAddClick}>
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Control>
          </Field>
        )}
      </div>
      <Title
        style={{ marginBottom: 0, display: "flex", justifyContent: "center" }}
      >
        Qualification Codes
      </Title>
      <div
        className="section-header"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Tab.Group style={{ paddingTop: "10px", marginBottom: 0 }}>
          <Tab
            active={tabState === "certifications"}
            onClick={() => setTabState("certifications")}
          >
            Certifications
          </Tab>
          <Tab
            active={tabState === "licenses"}
            value="licenses"
            onClick={(e) => handleTabChange("licenses")}
          >
            Licenses
          </Tab>
          <Tab
            active={tabState === "skills"}
            onClick={() => setTabState("skills")}
          >
            Skills
          </Tab>
          {!isReadOnly && (
            <Tab
              active={tabState === "deccoUCourses"}
              onClick={() => setTabState("deccoUCourses")}
            >
              DECCOU Courses
            </Tab>
          )}
        </Tab.Group>
      </div>
      <QaulificationsTable
        data={codes}
        isReadOnly={isReadOnly}
        loading={codesLoading}
        qualification={tabState}
      />
    </div>
  );
};

QualificationCodes.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
};
QualificationCodes.defaultProps = {
  isReadOnly: false,
};

export default QualificationCodes;
