/* eslint-disable react/destructuring-assignment */
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { Button, Generic, Icon, Title, Field, Control } from "rbx";
import React, { useMemo, useState, useEffect } from "react";
import { format } from "date-fns/esm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Button as MuiButton, useTheme, useMediaQuery } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useModal } from "../../context";
import {
  FIND_MANY_DOCUMENTS,
  DELETE_DOCUMENT_MUTATION,
  SIGNED_FILE_URL_QUERY,
} from "../../graphql/resources";
import GenericTable from "../GenericTable";
import { generateColumns } from "./columns";
import AddDocumentModal from "./components/AddDocumentModal";
import { customToast as toast } from "../../utils";
import { AccordionDetailsItem, AccordionItem, Confirmation } from "..";
import { usePermissions } from "../../hooks";
import MobileTable from "../ReportsTable/MobileTable";
import BoxLoader from "../DetailedDataTable/BoxLoader/BoxLoader";

const Resources = ({ readOnly }) => {
  const { setModalOpen } = useModal();

  const [isAdmin, setIsAdmin] = useState(false);
  const permissionLevels = usePermissions();
  const client = useApolloClient();

  const handleDownload = async (data) => {
    const { path } = data;
    const objectPath = path.split("/")[4];
    const { data: signedFileUrlData } = await client.query({
      query: SIGNED_FILE_URL_QUERY,
      variables: {
        input: {
          objectKey: objectPath,
        },
      },
    });
    const { signedFileUrl } = signedFileUrlData;
    window.open(signedFileUrl);
  };

  useEffect(() => {
    if (permissionLevels.webEditor) {
      setIsAdmin(true);
    }
  }, [permissionLevels]);

  const [deleteDocument] = useMutation(DELETE_DOCUMENT_MUTATION);

  const { data: docs, loading: loadingDocuments } = useQuery(
    FIND_MANY_DOCUMENTS,
    {
      variables: { where: {}, orderBy: { createdAt: "desc" } },
    }
  );

  const COLUMNS = useMemo(() => {
    const handleDelete = (data) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteDocument({
            variables: {
              where: {
                id: data.id,
              },
            },
            refetchQueries: [
              {
                query: FIND_MANY_DOCUMENTS,
                variables: { where: {}, orderBy: { createdAt: "desc" } },
              },
            ],
          });
          toast.success("Document deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Document.`);
        } finally {
          setModalOpen(false, "");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Document?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };

    const cols = generateColumns(handleDelete, handleDownload);
    if (isAdmin && !readOnly) {
      cols.unshift({
        Header: "",
        id: "delete",
        Cell: (cell) => (
          <Generic
            style={{ textAlign: "center", cursor: "pointer" }}
            title="Delete"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(cell.row.original);
            }}
          >
            <Icon color="danger">
              <FontAwesomeIcon icon="times" />
            </Icon>
          </Generic>
        ),
      });
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, readOnly, setModalOpen, deleteDocument]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <AddDocumentModal
        documents={docs?.findManyDocuments || []}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileTable
        showLoadingBar
        loading={loadingDocuments}
        showExportButton={false}
        title="Resources"
      >
        {loadingDocuments ? (
          <BoxLoader />
        ) : (
          docs?.findManyDocuments?.map((item) => (
            <AccordionItem key={item?.name} title={item?.description}>
              <AccordionDetailsItem label="Name" value={item?.name} />
              <AccordionDetailsItem
                label="Created At"
                value={format(new Date(item?.createdAt), "MM-dd-yyyy")}
              />
              <MuiButton
                fullWidth
                color="secondary"
                sx={{ marginTop: "1rem", color: theme.palette.common.white }}
                variant="contained"
                onClick={() => handleDownload(item)}
              >
                <Download />
              </MuiButton>
            </AccordionItem>
          ))
        )}
      </MobileTable>
    );
  }

  return (
    <div
      className={
        readOnly ? "dashboard-resources-container" : "resources-container"
      }
    >
      <div className={readOnly ? "dashboard-section-header" : "section-header"}>
        <Title>Resources</Title>
        {isAdmin && !readOnly && (
          <Field>
            <Control>
              <Button color="primary" onClick={handleAddClick}>
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Control>
          </Field>
        )}
      </div>
      <GenericTable
        columns={COLUMNS}
        data={docs?.findManyDocuments || []}
        loading={loadingDocuments}
      />
    </div>
  );
};

Resources.propTypes = { readOnly: PropTypes.bool };

Resources.defaultProps = {
  readOnly: false,
};

export default Resources;
