import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation } from "@apollo/client";
import AddDocumentForm from "./AddDocumentForm";
import {
  CREATE_DOCUMENT_MUTATION,
  FIND_MANY_DOCUMENTS,
  DELETE_DOCUMENT_MUTATION
} from "../../../graphql/resources";
import { customToast as toast } from "../../../utils";
import { useAuth } from "../../../context/AuthContext";

const INITIAL_STATE = {
  description: "",
  fileName: "",
  filePath: "",
  contactInfo: false
};

const AddDocumentModal = ({ onComplete, documents }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const [createDocument] = useMutation(CREATE_DOCUMENT_MUTATION);

  const [deleteDocument] = useMutation(DELETE_DOCUMENT_MUTATION);

  const performDelete = async (id) => {
    try {
      await deleteDocument({
        variables: {
          where: {
            id,
          },
        },
      });
    } catch (err) {
      toast.error(`Error deleting Document.`);
    };
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheck = () => {
    setInputs((prev) => ({ ...prev, contactInfo: !prev.contactInfo, description: "DECCO Cell Phone List" }));
  }

  const handleFileUpload = (response) => {
    const { filepath, name } = response;
    setInputs((prev) => ({ ...prev, fileName: name, filePath: filepath }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {

      // currently in progress on hold until s3 situation is resolverd
      if (inputs?.contactInfo) {
        const deleteDoc = documents.find((doc) => (doc.path.split("/")[4] === "DECCOContactInfo.pdf"));
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!deleteDoc) {
          const { id } = deleteDoc;
          await performDelete(id);
        }
      }

      await createDocument({
        variables: {
          data: {
            createdBy: authState?.user?.id,
            createdAt: new Date(),
            name: inputs.fileName,
            path: inputs.filePath,
            description: inputs.description,
          },
        },
        refetchQueries: [
          {
            query: FIND_MANY_DOCUMENTS,
            variables: { where: {}, orderBy: { createdAt: "desc" } },
          },
        ],
      });
      onComplete();
      toast.success("Document Saved Successfully");
    } catch (err) {
      toast.error("An error has occured, please try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="add-document-form">
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Add Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!inputs.filePath || !inputs.description}
              size="small"
              state={loading ? "loading" : ""}
              type="button"
              onClick={() => handleSubmit()}
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <AddDocumentForm
        inputs={inputs}
        onChange={handleChange}
        onCheck={handleCheck}
        onFileUpload={handleFileUpload}
      />
    </div>
  );
};

AddDocumentModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AddDocumentModal;
