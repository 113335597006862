import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { useTheme, useMediaQuery } from "@mui/material";
import { Button } from "rbx";
import { GET_PROJECT_LIST } from "../../graphql/project/project.queries";
import PROJECT_COLUMNS from "./columns";
import ProjectTable from "./components/ProjectTable/ProjectTable";
import { useModal } from "../../context";
import ProjectModal from "./components/ProjectModal/ProjectModal";
import ProjectFilters from "./components/ProjectFilters/ProjectFilters";
import { downloadLocalCsv } from "../../utils";
import MobileProjectList from "./MobileProjectList";
import { FiltersContainer } from "../../components";

const DEFAULT_PROJECTS_WHERE = {
  AND: [
    {
      name: {
        notIn: ["Disability", "Vacation", "Not Assigned"],
      },
    },
    { project_num: {} },
    { name: {} },
    { manager: {} },
    { supervisor: {} },
  ],
};

const DEFAULT_PROJECT_STATUS_FILTER = {
  isFilter: false,
};

const Projects = () => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [projectWhere, setProjectWhere] = useState(DEFAULT_PROJECTS_WHERE);
  const [projectStatusFilter, setProjectStatusFilter] = useState(
    DEFAULT_PROJECT_STATUS_FILTER
  );

  const { setModalOpen } = useModal();

  const { data: projectList, loading: loadingProjects } = useQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        orderBy: [{ name: "asc" }],
        where: projectWhere,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (projectList?.projects && !loadingProjects) {
      const rows = projectList.projects.map((project) => {
        const isActive =
          project.users?.length > 0 || project.contractors?.length > 0;

        const status = isActive ? "Active" : "Inactive";

        return { ...project, status };
      });

      if (projectStatusFilter.isFilter) {
        const status = projectStatusFilter.isActive ? "Active" : "Inactive";
        setProjects(rows.filter((row) => row.status === status));
      } else {
        setProjects(rows);
      }
    }
  }, [projectList, loadingProjects, projectStatusFilter]);

  const handleRowClick = (row) => {
    console.log(row)
    if (row?.original?.id) {
      history.push(`/project/${row.original?.id}`);
    } else {
      const selectedRow = projects.find(
        (project) =>
          project.project_num === row.values.project_num &&
          project.name === row.values.name
      );

      if (selectedRow) history.push(`/project/${selectedRow.id}`);
    }
  };

  const handleFiltersChange = (name, value) => {
    switch (name) {
      case "project_num":
        setProjectWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            {
              project_num: value
                ? { mode: "insensitive", contains: value }
                : {},
            },
            { name: prev.AND[2].name },
            { manager: prev.AND[3].manager },
            { supervisor: prev.AND[4].supervisor },
          ],
        }));
        break;

      case "name":
        setProjectWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            { project_num: prev.AND[1].project_num },
            {
              name: value ? { mode: "insensitive", contains: value } : {},
            },
            { manager: prev.AND[3].manager },
            { supervisor: prev.AND[4].supervisor },
          ],
        }));
        break;

      case "manager":
        setProjectWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            { project_num: prev.AND[1].project_num },
            { name: prev.AND[2].name },
            {
              manager: value ? { mode: "insensitive", contains: value } : {},
            },
            { supervisor: prev.AND[4].supervisor },
          ],
        }));
        break;

      case "supervisor":
        setProjectWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            { project_num: prev.AND[1].project_num },
            { name: prev.AND[2].name },
            { manager: prev.AND[3].manager },
            {
              supervisor: value ? { mode: "insensitive", contains: value } : {},
            },
          ],
        }));
        break;

      case "status":
        setProjectStatusFilter({ isFilter: value !== 2, isActive: value });
        break;

      default:
        break;
    }
  };

  const handleAddProject = () => {
    setModalOpen(
      true,
      <ProjectModal
        where={projectWhere}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const handleExport = async () => {
    try {
      await downloadLocalCsv(projects, "Projects", PROJECT_COLUMNS);
    } catch (error) {
      toast.error("Error exporting CSV");
    }
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileProjectList
        handleAddClick={handleAddProject}
        handleDetailsClick={handleRowClick}
        totalResults={projects.length}
        onExportClick={handleExport}
      />
    );
  }

  return (
    <div className="projects-container">
      <FiltersContainer
        buttonChildren={
          <Button className="add-btn" onClick={handleAddProject}>
            + Add
          </Button>
        }
        onExportClick={handleExport}
      >
        <ProjectFilters inputs={projectWhere} onChange={handleFiltersChange} />
      </FiltersContainer>

      <ProjectTable
        columns={PROJECT_COLUMNS}
        data={
          // sort by status
          projects.sort((a, b) => {
            if (a.status < b.status) return -1;
            if (a.status > b.status) return 1;
            return 0;
          })
        }
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default Projects;
