import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { Grid, 
  Typography,   
  useTheme,
  useMediaQuery 
} from "@mui/material";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import {
  deepOmit,
  customToast as toast,
  uppercaseFirstLetter,
  stripTimeZone,
} from "../../utils";
import { Loader, Confirmation, ModalWrapper } from "../../components";
import {
  DEFAULT_CERTIFICATION_COLUMNS_2,
  DEFAULT_LICENSE_COLUMNS,
  DEFAULT_DECCO_U_COLUMNS,
  DEFAULT_SKILLS_COLUMNS,
  generateColumns,
} from "./columns";
import {
  LicenseForm,
  CertificationForm,
  SkillForm,
  DeccoUForm,
  BaseballCardHeader,
  BaseballCardAvatar,
  BaseballCardButtons,
  BaseballCardTable,
  BaseballCardTrainings,
} from "./components";

import { usePermissions } from "../../hooks";
import { useModal, useAuth } from "../../context";

import {
  UPDATE_USER_MUTATION,
  GET_BASEBALL_CARD_EMPLOYEE,
  FIND_FIRST_AVERAGE_POINTS,
  GET_PROJECT,
  SIGNED_FILE_URL_QUERY,
} from "../../graphql";
import PrintBaseballCard from "./components/PrintBaseballCard";
import ProjectAssignmentHistory from "../ProjectAssignmentHistory";

import "./BaseballCard.scss";

const TAB_STATES = {
  licenses: "licenses",
  certifications: "certifications",
  skills: "skills",
};

const BaseballCard = () => {
  const [fileName, setFileName] = useState("");
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { state: authState, setState: setContext } = useAuth();

  const { setModalOpen } = useModal();
  const history = useHistory();
  const [tabState, setTabState] = useState("licenses");
  const [editMode, setEditMode] = useState(false);
  const [profileImageS3, setProfileImageS3] = useState("");
  const [printMode, setPrintMode] = useState(false);
  const [uploadProfilePermissions, setUploadProfilePermissions] =
    useState(false);


  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [editLicensePermissions, setEditLicensePermissions] = useState(false);
  const [editCertificationPermissions, setEditCertificationPermissions] =
    useState(false);
  const [editSkillPermissions, setEditSkillPermissions] = useState(false);
  const [editDeccoUPermissions, setEditDeccoUPermissions] = useState(false);
  const [exportBBCardPermissions, setExportBBCardPermissions] = useState(false);
  const [mobileButtonsOpen, setMobileButtonsOpen] = useState(false);

  const params = useParams();
  const { id } = params;

  const permissionLevels = usePermissions();

  useEffect(() => {
    // prevent users from accessing other users baseball cards
    if (authState?.user?.employeeNumber.trim() !== id) {
      if (
        !(
          permissionLevels?.superAdmin ||
          permissionLevels?.webEditor ||
          permissionLevels.fieldSupervisor ||
          permissionLevels?.admin
        )
      ) {
        history.push(
          `/baseball-card/${authState?.user?.employeeNumber.trim()}`
        );
      }
    }
  }, [authState?.user?.employeeNumber, id, history, permissionLevels]);

  const client = useApolloClient();

  const [employeeData, setEmployeeData] = useState({});
  const [loading, setLoading] = useState(true);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [projectName, setProjectName] = useState("Not found");

  const { refetch } = useQuery(GET_BASEBALL_CARD_EMPLOYEE, {
    variables: {
      where: {
        employeeNumber: {
          contains: id.trim(),
        },
      },
    },
    fetchPolicy: "network-only",
    onCompleted: ({ getEmployeeBaseballCard: data }) => {
      const omitted = deepOmit(data, ["__typename"]);

      const formattedLicenses = omitted.licenses.map((license) => ({
        ...license,
        expDate: license.expDate ? stripTimeZone(license.expDate) : null,
        issueDate: license.issueDate ? stripTimeZone(license.issueDate) : null,
      }));

      const formattedCertifications = omitted.certifications.map(
        (certification) => ({
          ...certification,
          expDate: certification.expDate
            ? stripTimeZone(certification.expDate)
            : null,
          issueDate: certification.issueDate
            ? stripTimeZone(certification.issueDate)
            : null,
        })
      );

      const formattedDeccoUCourses = omitted.deccoUCourses.map(
        (deccoUCourse) => ({
          ...deccoUCourse,
          expDate: deccoUCourse.expDate ? deccoUCourse.expDate : null,
          issueDate: deccoUCourse.issueDate
            ? stripTimeZone(deccoUCourse.issueDate)
            : null,
        })
      );

      const formattedSkills = omitted.skills.map((skill) => ({
        ...skill,
        expDate: skill.expDate ? stripTimeZone(skill.issueDate) : null,
        issueDate: skill.issueDate ? stripTimeZone(skill.issueDate) : null,
      }));

      setEmployeeData((prev) => ({
        ...prev,
        ...omitted,
        firstName: uppercaseFirstLetter(omitted.firstName),
        lastName: uppercaseFirstLetter(omitted.lastName),
        employeeNumber: omitted?.employeeNumber?.trim(),
        licenses: formattedLicenses,
        certifications: formattedCertifications,
        deccoUCourses: formattedDeccoUCourses,
        skills: formattedSkills,
      }));
      setLoading(false);
    },
  });

  useEffect(() => {
    if (permissionLevels.webEditor) {
      setUploadProfilePermissions(true);
      setEditLicensePermissions(true);
      setEditCertificationPermissions(true);
      setEditSkillPermissions(true);
      setEditDeccoUPermissions(true);
      setExportBBCardPermissions(true);

      setQualificationOptions([
        "Add License",
        "Add Certification",
        "Add Skill",
        "Add DECCO U Course",
      ]);
    }

    if (permissionLevels.superAdmin) {
      setUploadProfilePermissions(false);
      setEditLicensePermissions(true);
      setEditCertificationPermissions(true);
      setEditSkillPermissions(true);
      setEditDeccoUPermissions(true);
      setExportBBCardPermissions(true);

      setQualificationOptions([
        "Add License",
        "Add Certification",
        "Add Skill",
        "Add DECCO U Course",
      ]);
    }

    if (permissionLevels.fieldSupervisor) {
      setQualificationOptions(["Add Skill"]);
      setEditSkillPermissions(true);
    }
  }, [permissionLevels, editMode]);

  useEffect(() => {
    if (employeeData) {
      const getAverageCompanyPoints = async () => {
        try {
          const { data } = await client.query({
            query: FIND_FIRST_AVERAGE_POINTS,
            variables: {
              orderBy: [
                {
                  timeStamp: "desc",
                },
              ],
            },
          });

          setEmployeeData((prev) => ({
            ...prev,
            averageCompanyPoints: data?.findFirstAveragePoints?.points,
          }));
        } catch (err) {
          toast.error("An error has occured, please try again later");
        }
      };

      getAverageCompanyPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (employeeData && employeeData?.projectId) {
      const getProject = async () => {
        try {
          const { data: ProjectData } = await client.query({
            query: GET_PROJECT,
            variables: {
              projectId: employeeData?.projectId,
            },
          });

          setProjectName(ProjectData?.getProject?.name);
        } catch (err) {
          toast.error("An error has occured, please try again later");
        }
      };

      getProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, employeeData]);

  const COLUMNS = useMemo(() => {
    const handleDelete = async (qualId, type) => {
      const performDelete = async () => {
        try {
          await client.mutate({
            mutation: UPDATE_USER_MUTATION,
            variables: {
              where: {
                id: employeeData?.id,
              },
              data: {
                [type]: {
                  delete: [
                    {
                      id: qualId,
                    },
                  ],
                },
              },
              refetchQueries: ["getEmployeeBaseballCard", "getEmployees"],
              awaitRefetchQueries: true,
            },
          });
          toast.success("Qualification deleted succesfully.");
          refetch();
        } catch (err) {
          toast.error(
            "There was an error deleting the qualification. Please contact support."
          );
        } finally {
          setModalOpen(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message={`Are you sure you want to delete this ${type?.substring(
            0,
            type?.length - 1
          )}?`}
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    };

    if (!editMode) {
      return {
        licenses: DEFAULT_LICENSE_COLUMNS,
        certifications: DEFAULT_CERTIFICATION_COLUMNS_2,
        skills: DEFAULT_SKILLS_COLUMNS,
        deccoUCourses: DEFAULT_DECCO_U_COLUMNS,
      };
    }

    if (editMode) {
      if (permissionLevels.superAdmin || permissionLevels.webEditor) {
        return {
          licenses: generateColumns(
            handleDelete,
            refetch,
            DEFAULT_LICENSE_COLUMNS
          ),
          certifications: generateColumns(
            handleDelete,
            refetch,
            DEFAULT_CERTIFICATION_COLUMNS_2
          ),
          skills: generateColumns(
            handleDelete,
            refetch,
            DEFAULT_SKILLS_COLUMNS
          ),
          deccoUCourses: generateColumns(
            handleDelete,
            refetch,
            DEFAULT_DECCO_U_COLUMNS
          ),
        };
      }
    }

    if (permissionLevels.fieldSupervisor) {
      return {
        skills: generateColumns(handleDelete, refetch, DEFAULT_SKILLS_COLUMNS),
      };
    }
    return <p>Not Authorized</p>;
  }, [
    client,
    editMode,
    employeeData?.id,
    permissionLevels.fieldSupervisor,
    permissionLevels.superAdmin,
    permissionLevels.webEditor,
    refetch,
    setModalOpen,
  ]);

  const handleRowClick = (inputState) => {
    if (tabState === "licenses" && editLicensePermissions) {
      setModalOpen(
        true,
        <LicenseForm
          employeeData={employeeData}
          initialInputs={{
            expDate: inputState?.expDate ? inputState?.expDate : "",
            issueDate: inputState?.issueDate ? inputState?.issueDate : "",
            licenseNumber: inputState?.licenseNumber,
            id: inputState?.id,
            title: `${inputState?.codes?.title}  ${inputState?.codes?.state} ${inputState?.codes?.level}`,
            expirationType: inputState?.codes?.expirationType,
          }}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            try {
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
                onCompleted: () => {
                  refetch();
                },
              });
              toast.success("Successfully updated employee");
              setModalOpen(false, "");
            } catch (err) {
              toast.error("An error has occured, please try again later");
            }
          }}
        />
      );
    } else if (tabState === "certifications" && editCertificationPermissions) {
      setModalOpen(
        true,
        <CertificationForm
          employeeData={employeeData}
          initialInputs={{
            expDate: inputState?.expDate ? inputState?.expDate : "",
            issueDate: inputState?.issueDate ? inputState?.issueDate : "",
            certificationNumber: inputState?.certificationNumber,
            id: inputState?.id,
            title: `${inputState?.codes?.title}  ${inputState?.codes?.state} ${inputState?.codes?.level}`,
            expirationType: inputState?.codes?.expirationType,
          }}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            try {
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
                onCompleted: () => {
                  refetch();
                },
              });
              toast.success("Successfully updated employee");
              setModalOpen(false, "");
            } catch (err) {
              toast.error("An error has occured, please try again later");
            }
          }}
        />
      );
    } else if (tabState === "skills" && editSkillPermissions) {
      setModalOpen(
        true,
        <SkillForm
          employeeData={employeeData}
          initialInputs={{
            expDate: inputState?.expDate ? inputState?.expDate : "",
            issueDate: inputState?.issueDate ? inputState?.issueDate : "",
            certificationNumber: inputState?.certificationNumber,
            id: inputState?.id,
            title: `${inputState?.codes?.title}  ${inputState?.codes?.state} ${inputState?.codes?.level}`,
            expirationType: inputState?.codes?.expirationType,
          }}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            try {
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
                onCompleted: () => {
                  refetch();
                },
              });
              toast.success("Successfully updated employee");
              setModalOpen(false, "");
            } catch (err) {
              toast.error("An error has occured, please try again later");
            }
          }}
        />
      );
    }
  };

  const handleUpdateDeccoUCourse = (inputState) => {
    if (editDeccoUPermissions) {
      setModalOpen(
        true,
        <DeccoUForm
          employeeData={employeeData}
          initialInputs={{
            expDate: inputState?.expDate ? inputState?.expDate : "",
            issueDate: inputState?.issueDate ? inputState?.issueDate : "",
            certificationNumber: inputState?.certificationNumber,
            id: inputState?.id,
            title: `${inputState?.codes?.title}  ${inputState?.codes?.state} ${inputState?.codes?.level}`,
            expirationType: inputState?.codes?.expirationType,
          }}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            // if any values are dates, format them

            try {
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
                onCompleted: () => {
                  refetch();
                },
              });
              toast.success("Successfully updated employee");
              setModalOpen(false, "");
            } catch (err) {
              toast.error("An error has occured, please try again later");
            }
          }}
        />
      );
    }
  };

  const handleAddClick = (type) => {
    if (type === "Add DECCO U Course") {
      setModalOpen(
        true,
        <DeccoUForm
          employeeData={employeeData}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            try {
              setLoading(true);
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
              });
            } catch (err) {
              toast.error("An error has occured, please try again later");
            } finally {
              refetch();
              toast.success("Successfully created course");

              setModalOpen(false, "");
              setLoading(false);
            }
          }}
        />
      );
    } else if (type === "Add Certification") {
      setModalOpen(
        true,
        <CertificationForm
          employeeData={employeeData}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            try {
              setLoading(true);
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
              });
            } catch (err) {
              toast.error("An error has occured, please try again later");
            } finally {
              refetch();
              toast.success("Successfully created certification");

              setModalOpen(false, "");
              setLoading(false);
            }
          }}
        />
      );
    } else if (type === "Add Skill") {
      setModalOpen(
        true,
        <SkillForm
          employeeData={employeeData}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            try {
              setLoading(true);
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
              });
            } catch (err) {
              toast.error("An error has occured, please try again later");
            } finally {
              refetch();
              toast.success("Successfully created skill");

              setModalOpen(false, "");
              setLoading(false);
            }
          }}
        />
      );
    } else {
      setModalOpen(
        true,
        <LicenseForm
          employeeData={employeeData}
          mobile={downSm}
          onCancel={() => setModalOpen(false, "")}
          onComplete={async (data) => {
            try {
              setLoading(true);
              await updateUser({
                variables: {
                  where: {
                    id: employeeData?.id,
                  },
                  data,
                  refetchQueries: "active",
                  awaitRefetchQueries: true,
                },
              });
            } catch (err) {
              toast.error("An error has occured, please try again later");
            } finally {
              refetch();
              toast.success("Successfully created license");

              setModalOpen(false, "");
              setLoading(false);
            }
          }}
        />
      );
    }
  };

  const handleUploadImageChange = async (response) => {
    const { filepath } = response;
    try {
      await updateUser({
        variables: {
          data: {
            profileImage: { set: filepath },
          },
          where: {
            id: employeeData?.id,
          },
        },
        onCompleted: () => {
          refetch();
        },
      });

      setFileName(filepath);
      if (authState?.user.id === employeeData.userId) {
        setContext((prev) => ({
          ...prev,
          user: { ...prev.user, profileImage: filepath },
        }));
      }
      toast.success("Profile Image Saved Successfully");
    } catch (err) {
      toast.error("An error has occured, please try again later");
    }
  };

  const onEditClick = () => {
    setEditMode(!editMode);
  };

  const openAssignmentHistory = () => {
    setModalOpen(
      true,
      <ModalWrapper
        details=""
        title="Project Assignment History"
        onComplete={() => setModalOpen(false)}
      >
        <ProjectAssignmentHistory userId={employeeData?.id} />
      </ModalWrapper>
    );
  };

  const handlePrintClick = () => {
    setPrintMode(true);
  };

  useEffect(() => {
    if (employeeData?.profileImage) {
      const handleDownloadProfilePicture = async (data) => {
        const { profileImage } = data;
        const path = profileImage.split("/")[4];
        const { data: signedFileUrlData } = await client.query({
          query: SIGNED_FILE_URL_QUERY,
          variables: {
            input: {
              objectKey: path,
            },
            fetchPolicy: "network-only",
          },
        });
        const { signedFileUrl } = signedFileUrlData;
        setProfileImageS3(signedFileUrl);
      };
      handleDownloadProfilePicture(employeeData);
    }
  }, [employeeData, fileName, client]);

  const handleDownloadCellPhoneList = async () => {
    const { data: signedFileUrlData } = await client.query({
      query: SIGNED_FILE_URL_QUERY,
      variables: {
        input: {
          objectKey: "DECCOContactInfo.pdf",
        },
      },
    });
    const { signedFileUrl } = signedFileUrlData;
    window.open(signedFileUrl);
  };


  if (printMode) {
    return (
      <PrintBaseballCard
        employeeData={employeeData}
        profileImageS3={profileImageS3}
        onComplete={() => {
          window.print();
          setLoading(false);
          setPrintMode(false);
        }}
      />
    );
  }

  return (
    <Grid container className="baseball-card-container2" spacing={2}>
      {loading ? (
        <div style={{ position: "absolute", top: "40%", left: "50%" }}>
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          <Grid
            item
            maxHeight="100px"
            md={2}
            style={{ zIndex: 1 }}
            sx={{ display: { xs: "none", sm: "none", md: "inline" } }}
            xs={0}
          >
            <BaseballCardAvatar
              employeeData={employeeData}
              profileImageS3={profileImageS3}
            />
            <div className="bball-card-buttons-container">
              <Grid container className="user-title">
                <Grid item display="flex" md={12} xs={10}>
                  <Grid container>
                    <Grid item display="flex">
                      <Typography
                        className="c-secondary"
                        fontWeight="bold"
                        mr={1}
                      >
                        Username
                      </Typography>
                      <Typography>{employeeData?.email || ""}</Typography>
                    </Grid>
                    <Grid item display="flex">
                      <Typography
                        className="c-secondary"
                        fontWeight="bold"
                        mr={1}
                      >
                        Access
                      </Typography>
                      <Typography>
                        {employeeData?.userType || "user"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <BaseballCardButtons
                editMode={editMode}
                employeeData={employeeData}
                fileName={fileName}
                handleAddClick={handleAddClick}
                handleDownloadCellPhoneList={handleDownloadCellPhoneList}
                handleUploadImageChange={handleUploadImageChange}
                openAssignmentHistory={openAssignmentHistory}
                permissionLevels={permissionLevels}
                qualificationOptions={qualificationOptions}
                setLoading={setLoading}
                setModalOpen={setModalOpen}
                uploadProfilePermissions={uploadProfilePermissions}
                onEditClick={onEditClick}
              />
            </div>
          </Grid>
          <Grid item md={10} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BaseballCardHeader
                  employeeData={employeeData}
                  exportBBCardPermissions={exportBBCardPermissions}
                  handlePrintClick={handlePrintClick}
                  mobile={downSm}
                  profileImageS3={profileImageS3}
                  projectName={projectName}
                />
              </Grid>
              <Grid
                item
                className="bball-card-buttons-container"
                sx={{ display: { xs: "inherit", md: "none" } }}
                xs={12}
              >
                <Grid container className="user-title">
                  <Grid item display="flex" md={12} xs={10}>
                    <Grid container>
                      <Grid item display="flex" xs={12}>
                        <Typography
                          className="c-secondary"
                          fontWeight="bold"
                          mr={1}
                        >
                          Username
                        </Typography>
                        <Typography>{employeeData?.email || ""}</Typography>
                      </Grid>
                      <Grid item display="flex" xs={12}>
                        <Typography
                          className="c-secondary"
                          fontWeight="bold"
                          mr={1}
                        >
                          Access
                        </Typography>
                        <Typography>
                          {employeeData?.userType || "user"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    justifyContent="space-between"
                    md={0}
                    sm={2}
                  >
                    <Button
                      size="large"
                      onClick={() => setMobileButtonsOpen(!mobileButtonsOpen)}
                    >
                      <Icon className="c-secondary">
                        <FontAwesomeIcon icon={faBars} />
                      </Icon>
                    </Button>
                  </Grid>
                  <Grid item>
                    {mobileButtonsOpen ? (
                      <BaseballCardButtons
                        editMode={editMode}
                        employeeData={employeeData}
                        fileName={fileName}
                        handleAddClick={handleAddClick}
                        handleDownloadCellPhoneList={
                          handleDownloadCellPhoneList
                        }
                        handleUploadImageChange={handleUploadImageChange}
                        mobile={downSm}
                        openAssignmentHistory={openAssignmentHistory}
                        permissionLevels={permissionLevels}
                        qualificationOptions={qualificationOptions}
                        setLoading={setLoading}
                        setModalOpen={setModalOpen}
                        uploadProfilePermissions={uploadProfilePermissions}
                        onEditClick={onEditClick}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={1}
                sx={{ display: { xs: "none", md: "inherit" } }}
                xs={0}
              />
              <Grid item md={7} style={{maxWidth: downSm ? "80%" : "100%" }} xs={12}>
                <BaseballCardTable
                  COLUMNS={COLUMNS}
                  TAB_STATES={TAB_STATES}
                  editMode={!downSm && editMode}
                  employeeData={employeeData}
                  handleRowClick={handleRowClick}
                  loading={loading}
                  mobile={downSm}
                  permissionLevels={permissionLevels}
                  setTabState={setTabState}
                  tabState={tabState}
                />
              </Grid>
              <Grid item md={4}  xs={12}>
                <BaseballCardTrainings
                  COLUMNS={COLUMNS}
                  editMode={!downSm && editMode}
                  employeeData={employeeData}
                  handleUpdateDeccoUCourse={handleUpdateDeccoUCourse}
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

BaseballCard.propTypes = {};

export default BaseballCard;
